import React from 'react'

import styled from 'styled-components'
import colors from '../../styles/colors'

export const CardsContainer = styled.div.attrs({
  className: 'mv3-ns mv1 flex center flex-wrap'
})`
  overflow-y: hidden;
  overflow-x: scroll;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1120px) {
    flex-wrap: nowrap;
  }

  @media (min-width: 2232px) {
    justify-content: flex-start;
  }

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  &[class~='compact'] {
    @media (min-width: 1121px) {
      max-width: 740px;
    }

    @media (min-width: 1920px) {
      max-width: 45vw;
    }
  }
`

export const CardItem = ({
  locInfo = '',
  locDes = '',
  textProps = {},
  img,
  className = ''
}) => (
  <CardView className={`br3 dib ${className} ph0 mr2-m mr2-l mr2`}>
    {img && <img {...img} />}

    <CardDescription>
      <DealsLocInfo
        className='mb0 mv2 flex flex-row justify-left'
        {...textProps}
      >
        <Span className='pt2'>{locInfo}</Span>
      </DealsLocInfo>

      <DealsDesc className='mb0 flex flex-row justify-left' {...textProps}>
        <Span className='pv2'>{locDes}</Span>
      </DealsDesc>
    </CardDescription>
  </CardView>
)

const CardView = styled.div`
  width: 175px;
  height: 365px;
  box-sizing: border-box;
  flex: 0 0 auto;

  @media (min-width: 400px) {
    width: 200px;
    height: 425px;
  }

  @media (min-width: 1600px) {
    width: 300px;
    height: 525px;
  }

  @media (min-width: 2232px) {
    width: 300px;
    height: 565px;
  }

  img {
    max-height: 80%;
  }
`

const CardDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 175px;
  height: 35%;

  @media (min-width: 400px) {
    width: 200px;
  }

  @media (min-width: 1600px) {
    width: 260px;
  }
`
const DealsLocInfo = styled.p`
  font-size: 12px;
  color: ${colors.darkBlueI};
  height: 18%;
`

const DealsDesc = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.darkestBlue};
  height: 18%;

  @media (min-width: 320px) {
    font-size: calc(16px + 4 * ((100vw - 320px) / 1600));
  }

  @media (min-width: 1920px) {
    font-size: 2vmin;
    padding-top: 2px;
  }
`
const Span = styled.span``
