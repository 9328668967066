export default {
  DEALS_HEADLINE:
    'The best way to know when flights get cheap to dream destinations.',
  DEALS_SUB_HEADLINE:
    'Mistake fares and rideculously cheap fares to dream destinations delivered to you immediately so you can book simply and easily.',
  APP_UI_PREVIEW_SCREENS: [
    {
      locInfo: 'BOSTON, USA - DUBLIN, IRELAND',
      locDes: 'Explore Dublin pubs and history',
      img: {
        src:
          'https://res.cloudinary.com/staycircles/image/upload/v1573620454/assets/sc_web_landing/BOS_mrm4mt.png',
        alt: 'Explore Dublin pubs and history'
      }
    },
    {
      locInfo: 'NEW DELHI, INDIA - GENEVA, SWITZERLAND',
      locDes: 'Switzerland at your findertips',
      img: {
        src:
          'https://res.cloudinary.com/staycircles/image/upload/v1573620453/assets/sc_web_landing/DEL_fzvjbn.png',
        alt: 'Switzerland at your findertips'
      }
    },
    {
      locInfo: 'LAGOS, NIGERIA - SICILY, ITALY',
      locDes: 'Sail the Mediterranean and swim the warm beaches',
      img: {
        src:
          'https://res.cloudinary.com/staycircles/image/upload/v1573620467/assets/sc_web_landing/LOS_cdnuon.png',
        alt: 'Sail the Mediterranean and swim the warm beaches'
      }
    },
    {
      locInfo: 'MANILLA, PHILIPPINES - LOS ANGELES, USA',
      locDes: 'Walk Hollywood Boulevard and see the stars',
      img: {
        src:
          'https://res.cloudinary.com/staycircles/image/upload/v1573620466/assets/sc_web_landing/MNL_jvh92e.png',
        alt: 'Walk Hollywood Boulevard and see the stars'
      }
    },
    {
      locInfo: 'MILAN, ITALY - TOKYO, JAPAN',
      locDes: 'Japan’s megacity is yours to explore',
      img: {
        src:
          'https://res.cloudinary.com/staycircles/image/upload/v1573620468/assets/sc_web_landing/MXP_hb6sww.png',
        alt: 'Japan’s megacity is yours to explore'
      }
    },
    {
      locInfo: 'NAIROBI, KENYA - ROME, ITALY',
      locDes: 'Ancient Rome is awaiting discovery',
      img: {
        src:
          'https://res.cloudinary.com/staycircles/image/upload/v1573620455/assets/sc_web_landing/NBO_yhz9oy.png',
        alt: 'Ancient Rome is awaiting discovery'
      }
    },
    {
      locInfo: 'PHILADELPHIA, USA - BARCELONA, SPAIN',
      locDes: 'Barcelona for a quick weekend is in reach',
      img: {
        src:
          'https://res.cloudinary.com/staycircles/image/upload/v1573620454/assets/sc_web_landing/PHL_ua8qk0.png',
        alt: 'Barcelona for a quick weekend is in reach'
      }
    },
    {
      locInfo: 'PITTSBURG, USA - SHANGHAI, CHINA',
      locDes: 'China has never been more open',
      img: {
        src:
          'https://res.cloudinary.com/staycircles/image/upload/v1573620455/assets/sc_web_landing/PIT_gkjikr.png',
        alt: 'China has never been more open'
      }
    },
    {
      locInfo: 'PANAMA CITY, PANAMA - PARIS, FRANCE',
      locDes: 'Paris’s charm and the Effiel Tower',
      img: {
        src:
          'https://res.cloudinary.com/staycircles/image/upload/v1573620453/assets/sc_web_landing/PTY_aahemj.png',
        alt: 'Paris’s charm and the Effiel Tower'
      }
    },
    {
      locInfo: 'ISTANBUL, TURKEY - BOSTON, USA',
      locDes: 'United States’s classic city is stunning',
      img: {
        src:
          'https://res.cloudinary.com/staycircles/image/upload/v1573623164/assets/sc_web_landing/SAW_l9oahy.png',
        alt: 'United States’s classic city is stunning'
      }
    }
  ],
  FRIENDS_HEADLINE:
    'Stay with friends or friends-of-friends for free, and the world is yours.',
  FRIENDS_SUB_HEADLINE:
    'Dramatically lowering your lodging costs while traveling the world means more money in your pocket to share with those you love.',
  FRIENDS_UI_PREVIEW_SCREENS: [
    {
      locInfo: 'STAY IN LOS ANGELES - $0 / NIGHT',
      locDes: 'Los Angeles by day or night is another world with friends',
      img: {
        src:
          'https://res.cloudinary.com/staycircles/image/upload/v1573620586/assets/sc_web_landing/los_angeles.png',
        alt: 'Los Angeles by day or night is another world with friends'
      }
    },
    {
      locInfo: 'STAY IN KENYA - $0 / NIGHT',
      locDes: 'Local Kenyan friends know all the fun spots',
      img: {
        src:
          'https://res.cloudinary.com/staycircles/image/upload/v1573620584/assets/sc_web_landing/nairobi.png',
        alt: 'Local Kenyan friends know all the fun spots'
      }
    },
    {
      locInfo: 'STAY IN JAPAN - $0 / NIGHT',
      locDes: 'Local Kenyan friends know all the fun spots',
      img: {
        src:
          'https://res.cloudinary.com/staycircles/image/upload/v1573623949/assets/sc_web_landing/tokyo.png',
        alt: 'Local Kenyan friends know all the fun spots'
      }
    },
    {
      locInfo: 'STAY IN PARIS - $0 / NIGHT',
      locDes: 'Paris is for lovers - meet lovers through friends',
      img: {
        src:
          'https://res.cloudinary.com/staycircles/image/upload/v1573620585/assets/sc_web_landing/paris.png',
        alt: 'Paris is for lovers - meet lovers through friends'
      }
    },
    {
      locInfo: 'STAY IN BOSTON - $0 / NIGHT',
      locDes: 'Boston muddy waters swim best with friends',
      img: {
        src:
          'https://res.cloudinary.com/staycircles/image/upload/v1573620585/assets/sc_web_landing/boston.png',
        alt: 'Boston muddy waters swim best with friends'
      }
    }
  ]
}
