import React from 'react'

import styled from 'styled-components'

import colors from 'styles/colors'

import { CardsContainer, CardItem } from 'components/Landings/PreviewAppUICards'

const SectionAppUIPreviewHeadline = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 0;
  color: ${colors.darkestBlue};
  margin-bottom: 0.5vh;
  @media (max-width: 470px) {
    line-height: 1.5;
    font-size: 1.2rem;
  }
`

const SectionAppUIPreviewSubHeadline = styled.h1`
  font-size: 1rem;
  margin-top: 0.5px;
  font-weight: normal;
  color: ${colors.darkestBlue};
  @media (max-width: 470px) {
    line-height: 1.5;
    margin-bottom: 0.5vh;
  }
`
const AMAppScreenPreview = ({
  previews = [],
  mainHeading = '',
  subHeading = ''
}) => (
  <div className='mt5-ns mt4'>
    <SectionAppUIPreviewHeadline>{mainHeading}</SectionAppUIPreviewHeadline>

    <SectionAppUIPreviewSubHeadline className='dn db-ns'>
      {subHeading}
    </SectionAppUIPreviewSubHeadline>
    <CardsContainer>{previews.map(renderCardItem)}</CardsContainer>
  </div>
)

const renderCardItem = (card, index) => <CardItem key={index} {...card} />

export default AMAppScreenPreview
