import React from 'react'

import Helmet from 'react-helmet'

import styled from 'styled-components'

import colors from 'styles/colors'

import Layout from 'components/Layout'

import { HeroContainer, LandingBGHeadline } from 'components/Landings/Hero'
import { Text } from 'components/Landings/Explained'
import AMAppScreenPreview from 'components/Landings/AMAppScreenPreview'
import Footer from 'components/Landings/Footer'
import CheapFlightCard from 'components/Landings/CheapFlightCard'
import InvitationBadge from 'components/Landings/InvitationBadge'
import { Phone } from './adventure/components/_AMExplained'
import Icon from '../components/Icon'
import root from 'utils/windowOrGlobal'
import landingConstants from '../utils/landingConstants'
import { navigate } from 'gatsby'
import * as routes from 'config/routes'

const HomePage = props => (
  <Layout className='overflow-x-hidden'>
    <Helmet>
      <title>Stay with each other</title>

      <meta
        name='description'
        content='Book rooms with friends and friends-of-friends. Stay with friends for fun - or business travel where everybody wins.'
      />
      <meta
        name='image'
        content='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1542077900/assets/meta/Logo_White.png'
      />

      <meta itemProp='name' content='Stay with each other - StayCircles' />
      <meta
        itemProp='description'
        content='Book rooms with friends and friends-of-friends. Stay with friends for fun - or business travel where everybody wins.'
      />
      <meta
        itemProp='image'
        content='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1542077900/assets/meta/Logo_White.png'
      />

      <meta name='twitter:card' content='summary' />
      <meta name='twitter:title' content='Stay with each other - StayCircles' />
      <meta
        name='twitter:description'
        content='Book rooms with friends and friends-of-friends. Stay with friends for fun - or business travel where everybody wins.'
      />

      <meta name='og:title' content='Stay with each other - StayCircles' />
      <meta
        name='og:description'
        content='Book rooms with friends and friends-of-friends. Stay with friends for fun - or business travel where everybody wins.'
      />
      <meta
        name='og:image'
        content='https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto/v1542078118/assets/meta/Home.png'
      />
      <meta name='og:url' content='https://www.staycircles.com' />
      <meta name='og:site_name' content='StayCircles' />
      <meta name='og:type' content='website' />
    </Helmet>

    <Hero />

    <SectionContainer>
      <CheapFlightCard />

      <AMAppScreenPreview
        previews={landingConstants.APP_UI_PREVIEW_SCREENS}
        mainHeading={landingConstants.DEALS_HEADLINE}
        subHeading={landingConstants.DEALS_SUB_HEADLINE}
      />

      <AdventureMachineStore />

      <AMAppScreenPreview
        previews={landingConstants.FRIENDS_UI_PREVIEW_SCREENS}
        mainHeading={landingConstants.FRIENDS_HEADLINE}
        subHeading={landingConstants.FRIENDS_SUB_HEADLINE}
      />

      <div className='w-100 flex justify-center mt5-ns mt-4'>
        <CreateAccountButton
          children='Create Account'
          onClick={() => navigate(routes.SIGNUP)}
        />
      </div>
    </SectionContainer>
    <Footer />

    <InvitationBadge />
  </Layout>
)

const Hero = () => {
  return (
    <Container>
      <LandingBGHeadline>
        It's your turn <br />
        to see the world.
      </LandingBGHeadline>
    </Container>
  )
}

export const MapView = () => {
  return (
    <MapWrapper>
      <MapBG />

      <MapTextBox>
        <p>A map of your friends and friends-of-friends.</p>
        <br />
        <p>Search.</p>
        <p>Book.</p>
        <p>Stay with each other.</p>
      </MapTextBox>
    </MapWrapper>
  )
}

const Container = styled(HeroContainer)`
  text-align: left;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 344px) {
    justify-content: center;
    height: 95vh;
  }

  @media (min-width: 345px) and (max-width: 767px) {
    justify-content: center;
    height: 30vh;
  }
`

export const AdventureMachineStore = () => {
  return (
    <RowLanding className='flex flex-wrap justify-center mt5-ns mt4'>
      <ColumnLanding>
        <LandingText weight={900} size='medium'>
          <AMLogo /> Adventure Machine
        </LandingText>

        <div className='flex justify-center-ns justify-around flex-wrap ph5-ns pt4-ns'>
          <a
            target='_blank'
            href='https://itunes.apple.com/us/app/adventure-machine/id1412862317?ls=1&mt=8'
            className='mr5-ns'
            onClick={ga(
              'send',
              'event',
              'AM App Store Link',
              'open',
              'iOS App Store'
            )}
          >
            <StoreIcon
              src='https://res.cloudinary.com/staycircles/image/upload/v1572942190/assets/sc_web_landing/download-on-the-app-store-apple_1.png'
              className='mt2-ns mt1'
            />
          </a>

          <a
            target='_blank'
            href='https://play.google.com/store/apps/details?id=com.staycircles.am.android'
            onClick={ga(
              'send',
              'event',
              'AM App Store Link',
              'open',
              'Android Play Store'
            )}
          >
            <StoreIcon
              src='https://res.cloudinary.com/staycircles/image/upload/v1573449692/assets/sc_web_landing/google-play-badge_1_1.png'
              className='mt2-ns mt1'
            />
          </a>
        </div>
      </ColumnLanding>

      <ColumnLanding className='db-ns'>
        <AdventurePhone src='https://res.cloudinary.com/staycircles/image/upload/v1572942136/assets/sc_web_landing/Adventure_Machine_Phone_1.png' />
      </ColumnLanding>
    </RowLanding>
  )
}

const ga = (...args) => root && root.ga && root.ga(...args)

const RowLanding = styled.div`
  align-items: center;
`
const AdventurePhone = styled(Phone)`
  height: 700px;
  width: 350px;
`

const ColumnLanding = styled.div``

const AMLogo = styled(Icon).attrs({ name: 'am-mark' })`
  height: 75px;
`
const LandingText = styled(Text)`
  color: ${colors.darkestBlue};
`

const CreateAccountButton = styled.button.attrs({
  type: 'button'
})`
  color: ${colors.darkBlueII};
  border: 5px solid ${colors.lightBlueII};
  border-radius: 6px;
  padding: 20px 75px;
  background-color: ${colors.lightBlueI};
  cursor: pointer;
  font-weight: bold;

  @media (min-width: 1600px) {
    font-size: 20px;
  }

  @media (max-width: 600px) {
    margin-bottom: 4rem;
  }

  @media (min-width: 1600px) {
    font-size: 20px;
  }

  &:hover,
  &:focus {
    background-color: ${colors.darkIce};
  }
`
const MapWrapper = styled.div`
  width: 100vw;
  height: 50vw; /** Aspect ratio ~1.98 (3842w/1934h) */
  position: relative;
`
const StoreIcon = styled.img`
  width: 199px;
  max-width: 100%;
  height: auto;
`

const MapTextBox = styled.div`
  width: 90%;
  max-width: 500px;
  display: none;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 40px 80px;

  @media (min-width: 1024px) {
    display: block;
  }

  > p {
    font-size: 32px;
    font-weight: 300;
    line-height: 45px;
    text-align: center;
    color: ${colors.darkBlue};
  }
`
const sizes = {
  '1024': '1200',
  '1368': '1400',
  '1920': '2000',
  '2560': '3000',
  '3400': ''
}

const bgImageSizes = () => {
  const mediaQueries = Object.keys(sizes).map(query => {
    const width = sizes[query]
    const widthScale = width ? ',c_scale,w_' + width : ''
    return `
        @media (min-width: ${query}px) {
          background-image: url("https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto${widthScale}/v1517613283/assets/OpaqueMapView.jpg");
        }
      `
  })

  return mediaQueries.reduce(
    (str, qry) =>
      `
      ${str}
      ${qry}
    `,
    ''
  )
}

const MapBG = styled.div`
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% !important;

  background-image: url('https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_scale,w_500/v1518136824/assets/MapView-mobile.jpg');

  @media (min-width: 768px) {
    background-image: url('https://res.cloudinary.com/staycircles/image/upload/f_auto,q_auto,c_scale,w_800/v1518136824/assets/MapView-mobile.jpg');
  }

  ${bgImageSizes()};
`

const SectionContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  @media only screen and (max-width: 992px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (min-width: 992px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (min-width: 1232px) {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  @media (min-width: 1392px) {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  @media (min-width: 1600px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (min-width: 1750px) {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  @media (min-width: 1900px) {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  @media (min-width: 2200px) {
    padding-left: 12rem;
    padding-right: 12rem;
  }
`

export default HomePage
